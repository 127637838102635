<template>
  <div>
    <h1>Signing out...</h1>
  </div>
</template>
<script>
import 'firebase/auth';

import firebase from 'firebase/app';

import { AuthService } from '@/services/authService';

import { MUT_IS_LOADING, MUT_LOGOUT } from '../store';

export default {
  async mounted() {
    const hash = window.location.hash;
    const hashSearchParams = hash.includes('?') ? new URLSearchParams(hash.split('?')[1]) : new URLSearchParams();
    const isAuthEmbedded = hashSearchParams.get('embedded') === 'true';
    if (isAuthEmbedded && window.top !== window.self) {
      // Embedded logout behavior, for use in an iframe on the auth service
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        this.$store.commit(MUT_LOGOUT);
        localStorage.removeItem('refreshToken');
        if (refreshToken) {
          const authService = new AuthService();
          const parsed = authService.parseJwt(refreshToken);
          window.top.postMessage('revokeRefreshToken:' + parsed.jti, process.env.VUE_APP_AUTH_SVC_URL);
        }

        console.log('Successfully logged out');

        if (window.newrelic) {
          window.newrelic.addPageAction('Successfully logged out', {
            message: 'Successfully logged out from the embedded logout page',
            level: 'debug',
          });
        }
      } catch (e) {
        console.log('Problem logging out', e);
      } finally {
        window.top.postMessage('logoutComplete', process.env.VUE_APP_AUTH_SVC_URL);
      }
    } else {
      // We need to check if the user is logged in via the auth service
      if (this.$store.state.authTokens) {
        // If they are, we need to send them to the global logout endpoint
        window.location.href = `${process.env.VUE_APP_AUTH_SVC_URL}/logout`;
      } else {
        // User is signed in with old-auth, so we do the old logout flow
        const authService = new AuthService();
        this.$store.commit(MUT_IS_LOADING, true);
        await authService.logout();
        this.$store.commit(MUT_LOGOUT);
        localStorage.removeItem('refreshToken');
        await firebase.auth().signOut();
        await this.$router.replace('auth');
        this.$store.commit(MUT_IS_LOADING, false);
      }
    }
  },
};
</script>
